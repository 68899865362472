import { Box, Typography, Button } from '@mui/material'
import { ReviewForm } from './ReviewForm'
import InfoCard from './InfoCard'
import { useSearchParams } from 'react-router-dom'
import { useGetApplication } from '../../../api/application'

const ReviewApplication = () => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const { data, isLoading } = useGetApplication({ id: id })

  return (
    <>
      <Box mb={3}>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h2'>Application </Typography>
          <Button variant='outlined' onClick={() => window.history.back()} size='small'>
            Back
          </Button>
        </Box>
        <InfoCard data={data} />
      </Box>
      {!data?.isCompleted && !isLoading && <ReviewForm data={data} />}
    </>
  )
}

export default ReviewApplication
