import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Navigate } from 'react-router-dom'
import { REDIRECT_AUTHENTICATED_PATH } from './RedirectPath'
import { useMe, useOTP, useVerifyOTP } from '../../api/user'
import { CenteredContainer } from './CenteredContainer'

const OtpInput = ({ otp, setOtp, inputRefs }) => {
  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus()
    }
  }, [inputRefs])

  const handleChange = (index, e) => {
    const value = e.target.value
    if (isNaN(value)) return
    const newOtp = [...otp]
    newOtp[index] = value.substring(value.length - 1)
    setOtp(newOtp)
    if (value && index < 6 - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus()
    }
  }

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1)
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf('')].focus()
    }
  }

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus()
    }
  }

  return (
    <>
      {otp.map((value, index) => {
        return (
          <input
            key={index}
            type='text'
            ref={(input) => (inputRefs.current[index] = input)}
            value={value}
            onChange={(e) => handleChange(index, e)}
            onClick={() => handleClick(index)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            style={{
              width: '40px',
              height: '40px',
              margin: '5px',
              textAlign: 'center',
              fontSize: '1.2em',
            }}
          />
        )
      })}
    </>
  )
}

const PhoneVerification = () => {
  const { data } = useMe({})
  const { mutateAsync } = useOTP()
  const { mutateAsync: postVerivy } = useVerifyOTP()

  const inputRefs = useRef([])
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname
  const booleanSignUp = pathname.substring(pathname.lastIndexOf('/') + 1) === 'sign-up'

  const [showOtpInput, setShowOtpInput] = useState(false)
  const [otp, setOtp] = useState(new Array(6).fill(''))

  const { handleSubmit, control } = useForm({
    mode: 'onChange',
    // defaultValues: {
    //   phone: '60146552164',
    // },
  })

  const { enqueueSnackbar } = useSnackbar()
  const onSubmit = async (data) => {
    if (showOtpInput) {
      const emptyIndex = otp.findIndex((value) => value === '')
      if (emptyIndex !== -1) {
        inputRefs.current[emptyIndex].focus()
        return
      }
      const res = await postVerivy({ code: otp.join('') }).catch((e) => {
        enqueueSnackbar(e?.response?.data?.message ?? 'Error validating OTP', {
          variant: 'error',
        })
      })
      if (res?.status === 200) {
        enqueueSnackbar('Verified', {
          variant: 'success',
        })
        navigate('/user/reset-password')
      }
    } else {
      const res = await mutateAsync({ ...data, signup: booleanSignUp }).catch((e) => {
        enqueueSnackbar(e?.response?.data?.message ?? 'Error generating OTP', {
          variant: 'error',
        })
      })
      if (res?.status === 200) {
        enqueueSnackbar('OTP sent', {
          variant: 'success',
        })
        setShowOtpInput(true)
      }
    }
  }

  if (data) return <Navigate to={REDIRECT_AUTHENTICATED_PATH} />

  return (
    <CenteredContainer>
      <Avatar sx={{ bgcolor: 'black' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component='h1' variant='h5'>
        {booleanSignUp ? 'Sign Up' : 'Forgot Password'}
      </Typography>
      <Box component='form' onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, width: '100%' }}>
        <Controller
          name='phone'
          control={control}
          defaultValue=''
          rules={{
            required: 'Phone number is required',
            pattern: {
              value: /^(601)[0-46-9]-*[0-9]{7,8}$/,
              message: `Invalid phone number. Phone number should follow the format of 601xxxxxxxx.`,
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              margin='normal'
              label='Phone number'
              variant='outlined'
              autoComplete='off'
              value={value}
              fullWidth
              disabled={showOtpInput}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />
        {showOtpInput && (
          <Grid container justifyContent='center'>
            <OtpInput otp={otp} setOtp={setOtp} inputRefs={inputRefs} />
          </Grid>
        )}
        <Button type='submit' fullWidth variant='contained' sx={{ mt: 2, mb: 2 }}>
          {showOtpInput ? 'Verify' : 'Request OTP'}
        </Button>
        <Grid container justifyContent='right'>
          <Link href='/user/sign-in' variant='body2'>
            Already have an account? Sign in
          </Link>
        </Grid>
      </Box>
    </CenteredContainer>
  )
}

export default PhoneVerification
