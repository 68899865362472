import React from 'react'
import { Container, Typography, Box } from '@mui/material'

const clariosDetail = (
  <Box sx={{ pt: { xs: 6, sm: 6, lg: 8 } }}>
    <Container maxWidth='md' style={{ textAlign: 'center' }}>
      <img src='/assets/Clarios.png' width={250} alt={'Clarios'} />
      <Typography
        textAlign={{ xs: 'justify', sm: 'center' }}
        fontSize={{ xs: 15, md: 18 }}
        lineHeight='1.334'
        fontWeight='300'
      >
        <br />
        Clarios creates the most advanced battery technologies for virtually every type of vehicle.
        We are a global leader in advanced energy storage solutions, powering one in three of the
        world’s vehicles.
        <br /> <br /> We produce more than 150 million batteries – one-third of the industry’s
        output – every year, and we continue to build and expand our capacity to meet our customer’s
        future demand. <br /> <br /> Appointed distributorship of Energizer and VARTA by Clarios,
        Eco Battery serves the entire West Malaysia market across Johor Bahru, Malacca, Kuantan, and
        Kuala Lumpur, backed by authorized distributors in central and northern Malaysia.
      </Typography>
    </Container>
  </Box>
)

const industryDetail = (
  <>
    <Box display='flex' justifyContent='center' textAlign='center' mt={8} mb={3}>
      <Typography
        sx={{
          fontSize: { xs: '30px', md: '50px' },
          fontFamily: 'Corsica LX Bold',
          lineHeight: 1,
        }}
      >
        Powering Today, Into Tomorrow
      </Typography>
    </Box>
    <Box
      display='flex'
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems='center'
      justifyContent='center'
    >
      <Box display='flex' p={2} sx={{ justifyContent: { xs: 'center', md: 'right' } }}>
        <img
          src='/assets/Automotive.png'
          style={{ maxWidth: '60%', height: 'auto' }}
          alt={'Automotive'}
        />
      </Box>
      <Box display='flex' p={2} sx={{ justifyContent: { xs: 'center', md: 'left' } }}>
        <img
          src='/assets/Commercial.png'
          style={{ maxWidth: '60%', height: 'auto' }}
          alt={'Commercial'}
        />
      </Box>
    </Box>
  </>
)

const vartaDetail = (
  <>
    <Box
      style={{ position: 'relative' }}
      mt={8}
      mb={{ xs: '18rem', sm: '9rem', md: '11rem', lg: '5rem' }}
    >
      <img src='/assets/Blue.png' style={{ width: '100%', height: 'auto' }} alt={'Blue'} />
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        sx={{ width: { xs: '10rem', sm: '15rem', md: '25rem' } }}
      >
        <img src='/assets/Varta.png' style={{ width: '100%', height: 'auto' }} alt={'Varta'} />
      </Box>
      <Box
        style={{
          position: 'absolute',
          top: '80%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        sx={{
          width: { xs: 150, sm: 200, md: 250, lg: 300 },
          pt: { xs: 5, sm: 0 },
        }}
      >
        <img
          src='/assets/VartaLogo.png'
          style={{ width: '100%', textAlign: 'center' }}
          alt={'VartaLogo'}
        />
      </Box>
      <Box
        style={{
          position: 'absolute',
          top: '85%',
          transform: 'translate(0%, 5%)',
        }}
      >
        <Typography
          textAlign={{ xs: 'justify', lg: 'center' }}
          paddingLeft={{ xs: 2, sm: 4, md: 20 }}
          paddingRight={{ xs: 2, sm: 4, md: 20 }}
          fontSize={{ xs: 15, md: 18 }}
          lineHeight='1.334'
          fontWeight='300'
          fontFamily='Gothic'
        >
          <br />
          Experience superior manufacturing quality through VARTA® precision technology. VARTA®
          batteries drive cutting-edge fuel-saving innovations, such as start-stop engines,
          resulting in up to 20% lower emissions and efficient fuel consumption reduction. The
          patented PowerFrame® grid exemplifies exceptional production stability, delivering rapid
          charging, corrosion resistance, and dependable starting power in diverse weather
          conditions and applications.
        </Typography>
      </Box>
    </Box>
  </>
)

const energizerDetail = (
  <>
    <Box style={{ position: 'relative' }} mt={8} mb={{ xs: '11rem', sm: '2rem', md: '0' }}>
      <img src='/assets/Red.png' style={{ width: '100%', height: 'auto' }} alt={'Red'} />
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -65%)',
        }}
        sx={{ width: { xs: '10rem', sm: '15rem', md: '25rem' } }}
      >
        <img
          src='/assets/Energizer.png'
          style={{ width: '100%', height: 'auto' }}
          alt={'Energizer'}
        />
      </Box>
      <Box
        style={{
          position: 'absolute',
          top: '70%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        sx={{
          width: { xs: 150, sm: 200, md: 250, lg: 300 },
        }}
        pt={5}
      >
        <img
          src='/assets/EnergizerLogo.png'
          style={{ width: '100%', textAlign: 'center' }}
          alt={'EnergizerLogo'}
        />
      </Box>
      <Box
        style={{
          position: 'absolute',
          top: '75%',
          transform: 'translate(0%, 10%)',
        }}
      >
        <Typography
          textAlign={{ xs: 'justify', lg: 'center' }}
          paddingLeft={{ xs: 2, sm: 4, md: 20 }}
          paddingRight={{ xs: 2, sm: 4, md: 20 }}
          fontSize={{ xs: 15, md: 18 }}
          lineHeight='1.334'
          fontWeight='300'
          fontFamily='Gothic'
        >
          <br />
          The Energizer® car battery ensures consistent starting power across a diverse range of
          applications, including foreign and vintage cars. Energizer provides drivers with a
          dependable, top-performing, and globally recognized premium brand battery that serves as a
          secure and reliable partner, delivering exceptional performance that exceeds industry
          standards.
        </Typography>
      </Box>
    </Box>
  </>
)

export default function Brands() {
  return (
    <div id='brands'>
      {clariosDetail}
      {industryDetail}
      {vartaDetail}
      {energizerDetail}
    </div>
  )
}
