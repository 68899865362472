import { Routes, Route } from 'react-router-dom'
import Login from './auth/Login'
import AdminRedirectRoute from './auth/AdminRedirectRoute'
import AdminPrivateRoute from './auth/AdminPrivateRoute'
import ApplicationTable from './campaign/touch-n-go/ApplicationTable'
import ReviewApplication from './campaign/touch-n-go/ReviewApplication'
import Header from '../shared/Header'

const Page = () => {
  return (
    <Routes>
      <Route element={<AdminPrivateRoute />}>
        <Route path='/panel' element={<ApplicationTable />} />
        <Route path='/review-application' element={<ReviewApplication />} />
      </Route>
      <Route element={<Header />}>
        <Route path='/login' element={<Login />} />
        <Route path='/*' element={<AdminRedirectRoute />} />
      </Route>
    </Routes>
  )
}

export default Page
