import { Routes, Route } from 'react-router-dom'
import Header from '../shared/Header'
import SignIn from './auth/SignIn'
import PhoneVerification from './auth/PhoneVerification'
import ResetPassword from './auth/ResetPasswordForm'
import UserRedirectRoute from './auth/UserRedirectRoute'
import UserPrivateRoute from './auth/UserPrivateRoute'
import PointSummary from './battery-incentive/PointSummary'

const Page = () => {
  return (
    <Routes>
      <Route element={<UserPrivateRoute />}>
        <Route path='/battery-incentive' element={<PointSummary />} />
      </Route>
      <Route element={<Header />}>
        <Route path='/sign-in' element={<SignIn />} />
        <Route path='/sign-up' element={<PhoneVerification />} />
        <Route path='/forgot-password' element={<PhoneVerification />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/*' element={<UserRedirectRoute />} />
      </Route>
    </Routes>
  )
}

export default Page
