import { Routes, Route } from 'react-router-dom'
import ApplicationForm from './ApplicationForm'
import TermsCondition from './TermsCondition'
import Header from '../../shared/Header'
import { tngBanner } from '../../constant/image'

const Page = () => {
  return (
    <Routes>
      <Route element={<Header banner={tngBanner} />}>
        <Route path='/register' element={<ApplicationForm />} />
        <Route path='/*' element={<TermsCondition />} />
      </Route>
    </Routes>
  )
}

export default Page
