import React from 'react'
import Typography from '@mui/material/Typography'
import { Grid, Skeleton, Box } from '@mui/material'
import { useGetBatteryIncentivePoint } from '../../api/point'

const CategoryBadge = ({ color, textColor, text }) => (
  <Box
    sx={{
      backgroundColor: color,
      color: textColor,
      fontWeight: 'bold',
      fontSize: '1rem',
      width: '100%',
      height: '40px',
      lineHeight: '40px',
      borderRadius: 16,
      textAlign: 'center',
    }}
  >
    {text}
  </Box>
)

const TicketSummary = ({ label, value, color }) => (
  <Box
    sx={{
      backgroundColor: color,
      borderRadius: 10,
      width: 300,
      height: 180,
    }}
  >
    {[
      { content: label, bgColor: 'black' },
      { content: value, bgColor: 'transparent' },
    ].map(({ content, bgColor }, index) => (
      <Box
        key={index}
        sx={{
          borderRadius: 8,
          height: '50%',
          backgroundColor: bgColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography color='white' fontWeight='bold' fontSize='1.5rem'>
          {content}
        </Typography>
      </Box>
    ))}
  </Box>
)

const PointSummary = () => {
  const { data = [], isLoading } = useGetBatteryIncentivePoint()

  const infoArray = [
    { label: 'Business Name', id: 'companyName' },
    { label: 'Price Group', id: 'priceGroup' },
    { label: 'Eligibility Criteria', id: 'eligibilityCriteria' },
    { label: 'Purchase Period', id: 'purchasePeriod' },
  ]

  return (
    <Grid container rowGap={2} p={2}>
      <Grid item xs={12}>
        <Typography
          fontSize={{ xs: '2rem', md: '2.5rem' }}
          align='center'
          fontWeight={'bold'}
          color={'#D22630'}
        >
          HT ECO BATTERY INCENTIVE TRIP
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        mt={5}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-evenly'}
      >
        <Grid container spacing={2}>
          {infoArray.map((info, idx) => (
            <Grid item xs={12} key={idx}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography fontWeight='bold'>{info.label}</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {isLoading ? (
                    <Skeleton width={120} height={'100%'} />
                  ) : (
                    <Typography>{data?.[info.id]}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={1} mt={5} alignItems='center'>
          {['Category', 'Point', 'Quantity', 'Subtotal'].map((header, idx) => (
            <Grid item xs={3} key={idx}>
              <CategoryBadge color='#D22630' textColor='white' text={header} />
            </Grid>
          ))}

          {isLoading
            ? Array.from({ length: 5 }).map((_, idx) => (
                <React.Fragment key={idx}>
                  {Array.from({ length: 4 }).map((__, colIdx) => (
                    <Grid item xs={3} key={colIdx}>
                      <Skeleton width={'100%'} height={40} />
                    </Grid>
                  ))}
                </React.Fragment>
              ))
            : data.categories?.map((category, idx) => (
                <React.Fragment key={idx}>
                  <Grid item xs={3}>
                    <CategoryBadge color='#00449E' textColor='white' text={category.category} />
                  </Grid>
                  <Grid item xs={3}>
                    <CategoryBadge color='#F9F8F8' textColor='black' text={category.point} />
                  </Grid>
                  <Grid item xs={3}>
                    <CategoryBadge color='#F9F8F8' textColor='black' text={category.quantity} />
                  </Grid>
                  <Grid item xs={3}>
                    <CategoryBadge color='#F9F8F8' textColor='black' text={category.subtotal} />
                  </Grid>
                </React.Fragment>
              ))}
        </Grid>
      </Grid>

      <Grid item xs={12} md={4} mt={5}>
        <Grid container justifyContent={'center'} gap={3}>
          {isLoading
            ? Array.from({ length: 3 }).map((_, idx) => (
                <Skeleton
                  key={idx}
                  variant='rectangular'
                  width={300}
                  height={180}
                  sx={{ borderRadius: 10 }}
                />
              ))
            : data?.ticketSummary?.map(({ label, value, color }, idx) => (
                <TicketSummary label={label} value={value} color={color} key={idx} />
              ))}
        </Grid>
      </Grid>

      <Grid item xs={12} mt={5}>
        <Typography variant='h4'>
          By participating in this campaign, you agree to our{' '}
          <a
            href={'/assets/batteryIncentive/TermsAndConditions.pdf'}
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms & Conditions
          </a>{' '}
          and{' '}
          <a
            href={'/assets/batteryIncentive/PrivacyPolicy.pdf'}
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy.
          </a>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default PointSummary
